export default {
  namespaced: true,
  state: {},
  getters: {
    language: (state, getters, rootState) => rootState.language,
    showColorBox(state, getters) {
      const { colorList, mainSaleAttrShowMode } = getters
      if (mainSaleAttrShowMode === 1 && colorList.length > 0) {
        return true
      }
      return colorList.length > 1
    },
    mainSaleAttrShowMode: (state, getters, rootState) =>
      +rootState.productIntroData.detail?.mainSaleAttrShowMode,
    mainSaleAttribute: (state, getters, rootState) =>
      rootState.productIntroData.detail?.mainSaleAttribute,
    colorList(state, getters, rootState, rootGetters) {
      const productIntroData = rootState.productIntroData || {}

      const colorListData = productIntroData.colorData?.colorList || []
      const soldoutColor = productIntroData.soldoutColor || []

      const mallCode = rootState.mallCode // 色块售罄与mall有关
      const screenAbtConfig = rootGetters.screenAbtConfig || {}
      const mallSoldoutAb =
        screenAbtConfig?.mallchange?.param?.mallshow === 'auto'

      colorListData.forEach(item => {
        const { mall_stock, stock, is_on_sale, goods_id } = item || {}
        const mallSoldout = mallSoldoutAb
          ? !mall_stock?.find?.(mall => mall?.stock != 0)
          : mall_stock?.find?.(mall => mall.mall_code == mallCode)?.stock == 0

        const isSoldOut =
          mallSoldout || (goods_id && (!Number(stock) || !Number(is_on_sale)))
        const SoldoutGoodsIds = getSoldoutGoodsIds(soldoutColor, mallCode)

        if (isSoldOut || !!SoldoutGoodsIds[item.goods_id]) {
          item.isSoldoutColor = true
        } else {
          item.isSoldoutColor = false
        }
      })

      sortColorData(colorListData)

      return colorListData
    },
    colorGoodsDesc(state, getters, rootState, rootGetters) {
      const screenAbtConfig = rootGetters.screenAbtConfig || {}
      const showColorGoodsDesc =
        screenAbtConfig.selectcolor?.param?.colordesc === 'on'
      if (!showColorGoodsDesc) return void 0
      const pageComponents = rootGetters.pageComponents || {}
      return pageComponents.descriptionConf?.goodsDesc || void 0
    },
    beautyFindationAbt: (state, getters, rootState, rootGetters) => {
      const screenAbtConfig = rootGetters.screenAbtConfig || {}
      return screenAbtConfig.BeautyFindation
    },
    showFindMyShadeEntry(state, getters, rootState) {
      const productIntroData = rootState.productIntroData || {}
      return productIntroData.colorData?.showFindMyShadeEntry
    },
    showLargeImage(state, getters, rootState, rootGetters) {
      const screenAbtConfig = rootGetters.screenAbtConfig || {}
      const abtSupportLargeImageList =
        screenAbtConfig.selectcolor?.param?.largeimage === 'on'
      if (!abtSupportLargeImageList) return false
      const showColorMoreIcon = getters.mainSaleAttrShowMode === 2
      if (!showColorMoreIcon) return false
      const moduleRenderConfig = rootState.productIntroData?.moduleRenderConfig
      if (!Array.isArray(moduleRenderConfig) || !moduleRenderConfig.length) {
        return false
      }
      // 大图展示配置key: mobile_colorselector_enlarge
      const largeImageConfig = moduleRenderConfig.find(
        config => config.sceneKey === 'pc_colorselector_enlarge'
      )
      const imgConfig =
        largeImageConfig?.content?.[0]?.content?.props?.items?.[0]
      if (imgConfig) {
        return true
      }
    },
  },
}

const getSoldoutGoodsIds = (soldoutColor, mallCode) => {
  if (!soldoutColor.length) return {}
  let result = {}
  soldoutColor.forEach(item => {
    let mall = item.mall_stock.find(mall => mall.mall_code == mallCode)
    mall &&
      (mall.stock === 0 || Number(item.is_on_sale) === 0) &&
      (result[item.goods_id] = true)
  })
  return result
}

const sortColorData = colorList => {
  // 需求变更，排序规则按 SKC排序值正序>attr_sort正序 > value_sort倒序 > attr_value_id正序 > goods_id正序
  colorList.sort((a, b) => {
    if (a.isSoldoutColor && !b.isSoldoutColor) return 1
    if (!a.isSoldoutColor && b.isSoldoutColor) return -1
    return (
      a.skc_sort - b.skc_sort ||
      a.sort.attr_sort - b.sort.attr_sort ||
      b.sort.value_sort - a.sort.value_sort ||
      a.sort.attr_value_id - b.sort.attr_value_id ||
      a.goods_id - b.goods_id
    )
  })
}
