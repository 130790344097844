import { calMainEstimatedInfo, isAmountEmpty } from 'public/src/pages/goods_detail_v2/components/RecommendRelatives/CommonCart/utils.js'
import { getPopupInstance } from 'public/src/pages/goods_detail_v2/components/RecommendRelatives/GetTheLookNew/main-load.js'

export default {
  namespaced: true,
  state: {
    newGtlAndOutfitInfo: [] // 套装数据
  },
  getters: {
    // 组合购实验控制
    comboBuyAbtConfig(state, getters, rootState, rootGetters) {
      const { screenAbtConfig = {} } = rootGetters
      const showComboBuy = screenAbtConfig?.buytogether?.param?.buytogether === 'show'
      const isShowNewComboBuyStyleAbt = screenAbtConfig?.buytogether?.param?.buytogethershow === 'new'
      return {
        showComboBuy,
        isShowNewComboBuyStyleAbt,
        isShowComboBuyScreenStyleAbt: isShowNewComboBuyStyleAbt && screenAbtConfig?.buytogether?.param?.height === 'short',
      }
    },
    // 新搭配实验控制
    newOutfitAbt(state, getters, rootState, rootGetters){
      const { screenAbtConfig = {} } = rootGetters
      const newoutfit = screenAbtConfig?.newoutfit?.p || {}
      const showNewOutfit = newoutfit.newoutfit === 'new' // 展示新搭配模块

      return {
        showNewOutfit,
        showNewOutfitPhoto: showNewOutfit && newoutfit.pctype !== 'onlymodule', // 默认展示主图
        showNewOutfitModule: showNewOutfit && ['onlymodule', 'both'].includes(newoutfit.pctype), // 展示独立模块
        showNewOutfitSwitchIcon: newoutfit.outfitswitch !== 'none', // 搭配弹窗内有切换icon
        showNewOutfitSimilar: newoutfit.outfitsimilar === 'show', // 展示搭配弹窗内的相似品推荐位
        newOutfitCate: newoutfit.outfitshowcate || 'all' // 品类展示搭配模块控制
      }
    },
    // 搭配品类控制
    showOutfitCate(state, getters, rootState, rootGetters){
      const { isForwardCategory } = rootGetters
      const { newOutfitAbt } = getters 
      const { newOutfitCate = 'all' } = newOutfitAbt || {}
      const hiddenAll = newOutfitCate == 'onlygood' && !isForwardCategory // 命中abt仅正向品类展示搭配模块(老+新)
      if (hiddenAll) return false
      return true
    },
    // 独立的get the look模块
    showGtl (state, getters, rootState, rootGetters){
      const { screenAbtConfig = {}, goodsSn = '' } = rootGetters
      const { showOutfitCate, newOutfitAbt } = getters
      const { showNewOutfit } = newOutfitAbt
      if(!showOutfitCate || showNewOutfit) return false
      const { gtlshow = 'show' } = screenAbtConfig?.manyrecommend?.p || {}
      const getTheLookShowByAbt = ['show'].includes(gtlshow)
      return (getTheLookShowByAbt && goodsSn) || false
    },
    // 主图的View Outfits按钮
    showOutfitByAbt(state, getters, rootState, rootGetters){
      const { screenAbtConfig = {} } = rootGetters
      const { showOutfitCate, newOutfitAbt } = getters
      const { showNewOutfit } = newOutfitAbt
      if(!showOutfitCate || showNewOutfit) return false
      const { outfitshow = 'show' } = screenAbtConfig?.manyrecommend?.p || {}
      return outfitshow == 'show' || false
    },
    // 是否展示搭配模块入口 独立get the look模块
    showOutfitLookModule(state, getters) {
      const { newOutfitAbt, showOutfitCate } = getters 
      if (!showOutfitCate) return false
      const { showNewOutfitModule = false } = newOutfitAbt || {}
      const newGtlAndOutfitInfo = state.newGtlAndOutfitInfo || []
      return showNewOutfitModule && newGtlAndOutfitInfo.length > 0
    },
    // 商详价格，只选中主商品时使用
    cMainGoodsPrice(state, getters, rootState, rootGetters) {
      const newFlashPromotion = rootState.newFlashPromotion || {}
      const mallCode = rootGetters.mallCode
      const sheinClubPromotionInfo =
        rootGetters.sheinClubPromotionInfo || {}
      /**
       * 展示的Activity价差
       */
      let activityAmountWithSymbol = ''
      const skuDiscountPrice =
        rootState.skuInfo?.mall?.[mallCode]?.discountPrice || {}
      const mallDiscountPrice =
      rootState.productIntroData?.detail?.mall?.[mallCode]?.discountPrice || {}
      if (
        newFlashPromotion?.discount_value?.amountWithSymbol &&
        !isAmountEmpty(newFlashPromotion?.discount_value?.amount)
      ) {
        activityAmountWithSymbol =
          newFlashPromotion.discount_value.amountWithSymbol
      } else if (
        skuDiscountPrice?.amountWithSymbol &&
        !isAmountEmpty(skuDiscountPrice?.amount)
      ) {
        activityAmountWithSymbol = skuDiscountPrice.amountWithSymbol
      } else if (
        mallDiscountPrice?.amountWithSymbol &&
        !isAmountEmpty(mallDiscountPrice?.amount)
      ) {
        activityAmountWithSymbol = mallDiscountPrice.amountWithSymbol
      }
      let baseDiscount =
        rootState.productIntroData.detail?.mall?.[mallCode]?.unit_discount || 0

      const retailAmountWithSymbol =
        rootState.skuInfo?.mall?.[mallCode]?.retailPrice
          ?.amountWithSymbol ||
        rootState.productIntroData?.detail?.mall?.[mallCode]?.retailPrice
          ?.amountWithSymbol
      const getEstimatedInfo = rootGetters.getEstimatedInfo
      // 有到手价
      const hasEstimatedPrice = getEstimatedInfo?.value?.amountWithSymbol

      let amountWithSymbol = 0
      if (
        rootState.productIntroData?.isPaidUser &&
        sheinClubPromotionInfo?.price?.amountWithSymbol
      ) {
        amountWithSymbol = sheinClubPromotionInfo?.price?.amountWithSymbol || 0
        const sheinDiscount = sheinClubPromotionInfo?.discountValue
          ? Number(sheinClubPromotionInfo.discountValue)
          : 0
        if (!baseDiscount && sheinDiscount) {
          baseDiscount = sheinDiscount
        }
        if (sheinClubPromotionInfo?.discount_amount?.amountWithSymbol) {
          activityAmountWithSymbol =
            sheinClubPromotionInfo.discount_amount.amountWithSymbol
        }
      } else if (newFlashPromotion?.price?.amountWithSymbol) {
        amountWithSymbol = newFlashPromotion.price.amountWithSymbol
      } else {
        amountWithSymbol =
          rootState.skuInfo?.mall?.[rootGetters.mallCode]?.salePrice
            ?.amountWithSymbol ||
          rootState.productIntroData?.detail?.mall?.[rootGetters.mallCode]
            ?.salePrice?.amountWithSymbol
      }

      if (hasEstimatedPrice && getEstimatedInfo?.isSatisfied) {
        return calMainEstimatedInfo({
          getEstimatedInfo,
          language: rootState.language,
          retailAmountWithSymbol,
          activityAmountWithSymbol
        })
      }

      return {
        _isMainGoodsPrice: true,
        amountWithSymbol,
        /**
         * 折扣率
         */
        discount: baseDiscount,
        /**
         * 价差
         */
        discountAmountWithSymbol: activityAmountWithSymbol,
        /**
         * 原价
         */
        retailAmountWithSymbol,
        optimalCoupon: null,
        couponList: [],
        /**
         * 展示的Activity价差
         */
        activityAmountWithSymbol
      }
    },
    // 人工干预的置顶商品
    cccStoreGoodsRecIntervention(state, getters, rootState) {
      const { productIntroData } = rootState
      const { cccStoreGoodsRecIntervention } = productIntroData || {}
      return cccStoreGoodsRecIntervention?.goods ?? []
    },
  },
  actions: {
    async openGetTheLookNewPopup({ getters, rootState, rootGetters, state }, { initCateTab = '', activeIndex = 0 } = {}) {
      const GetTheLookNewPopup = await getPopupInstance()

      const { language, isHitComplianceMode, complianceMode, screenAbtConfig = {}, isPaidUser } = rootGetters
      const goodsId = rootState?.productIntroData?.detail?.goods_id
      const catId = rootState?.productIntroData?.detail?.cat_id
      const mainSkuCode = rootState.skuInfo?.sku_code
      const mainGoodsPrice = getters.cMainGoodsPrice
      const isShowItemChange = screenAbtConfig.newoutfit?.param?.outfitswitch !== 'none'
      const showRecommend = ['show-ymal', 'show'].includes(screenAbtConfig.newoutfit?.param?.outfitsimilar)
      const hideCateTab = screenAbtConfig.newoutfit?.param?.outfitsimilar === 'show-ymal'
      // const hideCateTab = true
      // const showCateRecommend = true
      const data = state.newGtlAndOutfitInfo
      const options = {
        goodsId,
        catId,
        mainSkuCode,
        data,
        language,
        initCateTab,
        isPaidUser,
        mainGoodsPrice,
        isShowItemChange,
        isHitComplianceMode,
        complianceMode,
        activeIndex,
        config: {
          hideCateTab,
          showRecommend
        }
        // callback: {
        //   onQuickViewLogin: (addToWish) => {
        //     commit('showLoginModal', {
        //       type: 'qv-add-wish',
        //       originRef: {
        //         addToWish
        //       }
        //     })
        //   }
        // }
      }
      console.log('openGetTheLookNewPopup options', options)
      GetTheLookNewPopup.open(options)
    }
  },
  mutations: {
    updateNewGtlInfo(state, payload) {
      state.newGtlAndOutfitInfo = payload
    },
  }
}
