import Size from './Size'
import Color from './Color'
import AddBtn from './AddBtn'
import SimilarFloat from './SimilarFloat'
import Recommend from './Recommend'

export default {
  Size,
  Color,
  AddBtn,
  SimilarFloat,
  Recommend,
}
