import { isMobile as isPhone, template } from '@shein/common-function'
export default {
  namespaced: true,
  state: {
  },
  getters: {
    productIntroData: (state, getters, rootState) => rootState.productIntroData,
    screenAbtConfig: (state, getters) => getters.productIntroData?.screenAbtConfig || {},
    mallCode: (state, getters) => getters.productIntroData?.mallCode,
    language: (state, getters, rootState) => rootState.language,
    findSimilarFloatInfo: (state, getters, rootState) => rootState.SimilarFloat.findSimilarFloatInfo || {},
    skuInfo: (state, getters, rootState) => rootState.skuInfo || {},
    promotionInfo: (state, getters) => getters.productIntroData.detail?.mall?.[getters.mallCode]?.promotionInfo || [],
    externalSkuCode: (state, getters, rootState) => rootState?.externalSkuCode,
    isAllSoldOut: (state, getters) => {
      const { detail } = getters.productIntroData || {}
      const { is_on_sale, stock } = detail || {}
      return +is_on_sale === 0 || +stock === 0
    },
    skuMapCompose: (state, getters, rootState) => {
      const { productInfo } = rootState.hotModules || {}
      const { skuMapCompose: hotSkuMapCompose } = productInfo || {}
      const { skuMapCompose } = getters.productIntroData || {}
      return Object.assign({}, skuMapCompose || {}, hotSkuMapCompose || {})
    },
    dataMapCompose: (state, getters) => {
      const { productIntroData, skuMapCompose } = getters
      const { sizeFormatData } = productIntroData || {}
      const { dataMapCompose } = sizeFormatData || {}
      const { dataMap, dataIdMap } = dataMapCompose || {}
      const { skuAllInfoMap, skuAllInfo } = skuMapCompose || {} 
      Object.keys(skuAllInfoMap || {}).forEach(skuCode => {
        const { key, idKey } = skuAllInfoMap[skuCode] || {}
        const stock = skuAllInfo?.[skuCode]?.stock
        if (stock !== undefined) {
          dataMap[key] = stock
          dataIdMap[idKey] = stock
        }
      })
      return dataMapCompose
    },
    catIdsMap: (state, getters) => {
      const { productIntroData } = getters
      const parentCats = productIntroData?.parentCats || []
      if (!parentCats?.cat_id) return {}
      const result = {}
      const arr = [parentCats]
      for(let item of arr) {
        const { cat_id, children } = item || {}
        result[cat_id] = 1
        if (children?.length) {
          arr.push(...children)
        }
      }
      return result
    },
    sizeComposeData: (state, getters, rootState, rootGetters) => {
      const { SiteUID } = rootState
      const { productIntroData, language, skuMapCompose, dataMapCompose } = getters
      const { isPaidUser } = rootGetters
      const { sizeFormatData, sizeInfoDes, multiLocalSize, detail, getOtherOptions, apolloConfig } = productIntroData || {}
      const { complianceMode = false, complianceTipsMode = false, isHitComplianceMode } = apolloConfig || {}
      const { saleAttrList, tackInfoDes, relatedLocalSize } = sizeFormatData || {}
      const { GB_cssRight, IS_RW, langPath } = gbCommonInfo || {}
      const constant = {
        cssRight: GB_cssRight, 
        isRw: IS_RW, 
        langPath,
        siteUid: SiteUID,
        isPaidUser,
        complianceTipsMode,
        complianceMode,
        isHitComplianceMode,
      }
      return {
        language,
        sizeInfoDes,
        localSize: multiLocalSize,
        skuMapCompose,
        baseInfo: detail,
        saleAttrList,
        dataMapCompose,
        tackInfoDes,
        relatedLocalSize,
        constant,
        getOtherOptions,
      }
    },
    sizeConfig: (state, getters, rootState, rootGetters) => {
      const { pageComponents } = rootGetters || {}
      const { checkNotSelectedTips } = rootState.AddBtn || {}  
      const { productIntroData, screenAbtConfig, catIdsMap } = getters
      const { sizeFormatData, apolloConfig, showMallSite, detail } = productIntroData || {}
      const { OPEN_DETAIL_SIZE_IDMAP, sizeTipsConfig, complianceMode, isHitComplianceMode } = apolloConfig || {}
      const { heightCatIds, showTaxation } = sizeTipsConfig || {}
      const { IS_RW } = gbCommonInfo || {}
      const { sizeguidefit, sizepriority, defaultlocalsize, longsize, NoSkuClick, goodsSizeGroupRelated, Onesizeno, mallchange, goodsMainAttPicShow } = screenAbtConfig || {}
      const longsizeAbt = longsize?.p?.longsize
      const showSizeGuideInTips = sizeFormatData?.showSizeGuide && pageComponents?.sizeGuideConf?.isShow
      const showSizeFeedBack = (IS_RW ? ['769', '909'] : ['1888', '2030']).some(item => !!catIdsMap[item])
      const goodsSizeGroupRelatedValue = goodsSizeGroupRelated?.p?.goodsSizeGroupRelated
      const shieldOneSizeCatId = [1864, 2032, 2273, 3195, 3634, 3637, 3657]
      const hideOneSize = Onesizeno?.param?.Onesizeno === 'noshow' && shieldOneSizeCatId.some(item => !!catIdsMap[item])
      return {
        from: 'detail',
        eventClickId: '1-8-6-5',
        isMobile: typeof window !== 'undefined' ? !!isPhone() : false,
        showSizeGuideInTips,
        sizeGuideInTitle: false,
        sizeSaleAttrId: sizeFormatData?.sizeSaleAttrId,
        checkNotSelectedTips,
        isNewSizeLocal: true,
        showHightSize: heightCatIds?.some?.(item => !!catIdsMap[item]),
        longsizeTowLine: ['twofold', 'twounfold'].includes(longsizeAbt),
        openFoldSizes: longsizeAbt && !['oneunfold', 'twounfold'].includes(longsizeAbt),
        isNoSkuClick: NoSkuClick?.param === 'type=2',
        showSizeFeedBack,
        sizeGroupPositionAbt: ['mid', 'bottom', 'fold'].includes(goodsSizeGroupRelatedValue) ? goodsSizeGroupRelatedValue : '',
        hideOneSize,
        showTaxation,
        showMallSite: showMallSite && detail?.mall_info?.length > 0,
        openAutoCheckMall: mallchange?.param?.mallshow === 'auto',
        showAttrImgPopover: goodsMainAttPicShow?.param?.goodsMainAttPicShow === 'on',
        complianceMode,
        isHitComplianceMode,
        OPEN_DETAIL_SIZE_IDMAP,
        sizepriority: sizepriority?.param?.sizepriority || 'off',
        defaultlocalsize: defaultlocalsize?.param?.defaultlocalsize || 'old',
        sizeguidefit: sizeguidefit?.param?.sizeguidefit || 'off',
      }
    },
    findSimilarFloatParams: (state, getters) => { 
      const { language, productIntroData, skuInfo, findSimilarFloatInfo } = getters || {}
      return {
        isQuickAdd: false,
        language,
        productIntroData,
        skuInfo,
        update: findSimilarFloatInfo?.show,
        ...(findSimilarFloatInfo || {}),
      }
    },
    tipsCombo: (state, getters, rootState, rootGetters) => {
      const { pageComponents } = rootGetters || {}
      const { SiteUID, newFlashPromotion, cccConfig, showHeelHeight, cmInchInfo, heelHeight, hotModules } = rootState || {} 
      const { productIntroData, promotionInfo, screenAbtConfig, language, catIdsMap } = getters || {} 
      const { SHEIN_KEY_PC_25123, SHEIN_KEY_PC_25124, SHEIN_KEY_PC_28172 } = language || {}
      const { detail, apolloConfig, sizeFormatData } = productIntroData || {}
      const commentInfo = hotModules.comment.commentInfo || {}
      const { saleAttrList } = sizeFormatData || {}
      const { polyAttrSize } = saleAttrList || {}
      const { tips, isReady } = cccConfig || {}
      const { truetosizetips, onlyxleft, goodsdetailtipstitlebold } = screenAbtConfig || {}
      const { max: defaultMax, min: defaultMin, offsetSizeConfig = [], catIds, trueToSizeStrongCatId } = apolloConfig?.sizeTipsConfig || {}
      // 从apolloConfig中获取当前站点的最大最小值，若该站点没有配置则使用默认值
      const { max = defaultMax, min = defaultMin } = offsetSizeConfig.find(item => {
        const { SiteUID: ids } = item || {}
        return ids?.includes?.(SiteUID)
      }) || {}

      let flashSaleInfo, underPriceInfo
      promotionInfo?.forEach?.(item => {
        const { typeId } = item || {}
        if (typeId == 10) flashSaleInfo = item
        if (typeId == 30) underPriceInfo = item
      })
      // 评论尺码真实提示相关数据
      let trueToSizeStrongText = ''
      const defaultTrueToSizeText = detail?.productDetails?.find(item => String(item.attr_id) == '146')?.attr_value || tips || ''
      const true_size = commentInfo?.percent_overall_fit?.true_size || ''
      const true_size_num = Math.round(+true_size?.replace?.('%', '') || 0)
      const comment_num = commentInfo?.comment_num || 0
      const showTrueToSizeStrongAbt = truetosizetips?.param?.truetosizetips === 'A'
      const showTrueToSizeStrong = showTrueToSizeStrongAbt && polyAttrSize?.length && true_size_num >= 90 && comment_num >= 10 && trueToSizeStrongCatId?.some?.(item => catIdsMap[item])
      if (!defaultTrueToSizeText && showTrueToSizeStrong) {
        trueToSizeStrongText = template('', SHEIN_KEY_PC_28172)
      }
      // 评论尺码偏大偏小提示
      let sizeTipsText = ''
      const { size_tips: sizeTips = {} } = commentInfo || {}
      const large =  Number(sizeTips?.large?.replace?.('%', '') || '')
      const small =  Number(sizeTips?.small?.replace?.('%', '') || '')
      const isHitCatId = catIds?.some?.(item => catIdsMap[item])
      const isExceptionSize = !defaultTrueToSizeText && isReady && (large >= max || small >= max) && Math.abs(large - small) >= min 
      const isLargeSizeTips = large >= max
      if (!defaultTrueToSizeText && isHitCatId && isExceptionSize) {
        sizeTipsText = (isLargeSizeTips ? SHEIN_KEY_PC_25123 : SHEIN_KEY_PC_25124)
      }
      return {
        newFlashPromotionData: productIntroData?.newFlashPromotion || newFlashPromotion || {},
        flashSaleInfo,
        underPriceInfo,
        soldOutTipsLimitNum: onlyxleft?.p?.onlyxleft === 'ten' ? 10 : 5,
        showSizeStockTips: !pageComponents?.qtyConf?.isShow,
        defaultTrueToSizeText,
        isLargeSizeTips,
        sizeTipsText,
        showHeelHeight,
        trueToSizeText: true_size,
        trueToSizeStrongText,
        cmInchInfo,
        heelHeight,
        sizeTipsBold: goodsdetailtipstitlebold?.param?.goodsdetailtipstitlebold === 'bold',
      }
    },
    sizeRecommendData: (state, getters) => {
      const { screenAbtConfig, productIntroData } = getters
      const { Selfsizerecommended } = screenAbtConfig || {}
      const { rule_id, rule_type, sizeFormatData } = productIntroData || {}
      const { oneSizeStatus } = sizeFormatData || {}
      // 1 自有推荐 2 第三方推荐推荐
      let sizeRecommendType = Selfsizerecommended?.param !== 'type=C' ? 
        (Selfsizerecommended?.param == 'type=A' && rule_id != 0 ? 1 : 2)
        : ''
      // RW不接入第三方推荐
      const { IS_RW } = gbCommonInfo || {}
      if (sizeRecommendType === 2 && IS_RW) sizeRecommendType = ''

      return {
        showSelfRecommend: sizeRecommendType === 1 && !oneSizeStatus,
        sizeRecommendType,
        selfRecommendType: rule_id != 0 ? rule_type : '',
      }
    },
    sizeModalComb: (state, getters, rootState, rootGetters) => {
      const { productIntroData } = getters
      const { isPaidUser } = rootGetters
      const { sizeFormatData, getOtherOptions } = productIntroData || {}
      const { saleAttrList } = sizeFormatData || {}
      const { skcSaleAttr, skuList } = saleAttrList || {}
      return {
        saleAttrList: {
          skc_sale_attr: skcSaleAttr,
          sku_list: skuList,
        },
        relatedGoodSizes: getOtherOptions?.[0] || {},
        productIntroData: {
          isPaidUser,
          multiLocalSize: productIntroData.multiLocalSize,
          detail: productIntroData.detail,
          sizeInfoDes: productIntroData.sizeInfoDes,
          localSizeList: productIntroData.localSizeList,
          rule_type: productIntroData.rule_type,
          rule_id: productIntroData.rule_id,
          cccAttrGroups: productIntroData.cccAttrGroups
        }
      }
    },
  },
}
